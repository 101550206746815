window.byid = function ( id ) { return document.getElementById( id ) }
window.bycl = function ( id ) { return document.getElementByClassName( id ) }
window.query = window.q = function ( v, base ) { return ( base || document ).querySelector( v ) }
window.queryAll = function ( v, base ) { return ( base || document ).querySelectorAll( v ) }

// move this into a .js file and import it properly, depends how often we use it?
import { FetchRequest } from '@rails/request.js' //https://github.com/rails/request.js
window.bring = async function ( kind, url, options = {} ) {
  if ( !options['noturbo'] ) options['responseKind'] = 'turbo-stream' // default to turbo

  const request = new FetchRequest( kind, url, options )
  const response = await request.perform()
  // console.log( "response is", response )

  // run after the turbo-stream does?  How much time is needed?  was 100
  if ( response.ok ) {
    if ( options['ok'] ) setTimeout( () => { options['ok'].call( null, response ) }, 30 )

  } else {
    // error handling, 1st arg is 'this'
    if ( options['fail'] ) {
      options['fail'].call( null, response )
    } else {
      const msg = await response.text
      byid( 'flash' ).innerHTML = `<h2>Error: ${response.statusCode}</h2><pre>${msg.substring( 0, 1200 )}</pre>`
    }
  }
  return response
}

window.enter_submit = function ( e ) {
  if ( e.code == 'Enter' && !e.shiftKey && !e.ctrlKey ) {
    // console.log( 'submtiting from enter', e )
    e.preventDefault()
    e.target.closest( 'form' ).requestSubmit()
    return false
  }
}

// also have to put in turbo_stream_actions_helper
//https://marcoroth.dev/posts/guide-to-custom-turbo-stream-actions
// render turbo_stream: turbo_stream.js( "$('textarea').val('');$('.btn-orange').val('Update all')" )
import { StreamActions } from "@hotwired/turbo"

StreamActions.js = function () {
  console.log('streamactions js')
  const text = this.getAttribute( "text" )
  eval( text ) //console.log( message )
}
StreamActions.check_convo = function () {
  const text = this.getAttribute( "text" );
  if ( typeof cc != "undefined" ) cc.check_convo( text );
}
// this isn't ever running for some reason?
StreamActions.redirect = function () {
  const text = this.getAttribute( "text" ) || this.getAttribute( "to" )
  if (user && user.id == 1) console.log( "stream.redirect", text, this ) // something is 
  Turbo.visit( text );
}

// if we use .templateContent, which is tempting, it returns a documentFragment which is a huge PITA to work with besides appending
// update #flash & scrollTo it?
// ideally would re-render the form showing which rows have errors & why
// can access this from this.templateContent
// or pass in templateargument
// fl.innerHTML = `<div class="${this.getAttribute( 'kind' )}">${this.getAttribute( "text" )}</div>`
StreamActions.flash = function () {
  let fl = document.getElementById( 'flash_here' )
  fl ||= document.getElementById( 'flash' )
  let er = document.createElement( 'div' )
  er.className = this.getAttribute( 'kind' )
  er.appendChild( this.templateContent )
  fl.replaceChildren( er )

  // fl.innerHTML = `<div class="${this.getAttribute( 'kind' )}"></div>`
  // fl.firstChild.appendChild( this.templateContent )
  fl.scrollIntoView( { behavior: 'smooth' } )
}

// StreamActions.toggle = function () {
//   const cl = this.getAttribute( "class" )
//   const targets = this.getAttribute( "targets" )
//   console.log( "toggle", cl, targets )
//   $( targets ).toggleClass( cl )
// }


// CURSORS
// ink trail https://codepen.io/ksenia-k/pen/rNoBgbV
// can't get this to work that well, lets just be active on the *first* page someone visits
// turbo-permanent isn't working
// why didn't I like having them stay? because I 
window.cursor_ink_setup = function () {
  if ( window.did_cursor ) {
    window.cursor_disabled = true
    // window.removeEventListener( 'click', cursorclick )
    // window.removeEventListener( 'mousemove', cursormove )
    // window.removeEventListener( 'touchmove', cursormovet )
    if ( document.querySelector( "#inkcanvas" ) ) document.querySelector( "#inkcanvas" ).remove()
    return
  }
  window.did_cursor = true // only do it on the first page.
  // if ( document.querySelector( "#inkcanvas" ) ) return console.log( "already created" );
  let c = document.createElement( "canvas" )
  c.id = "inkcanvas"
  document.body.append( c )

  const canvas = document.querySelector( "#inkcanvas" );
  // canvas.setAttribute( 'data-turbo-permanent', 1 )
  const ctx = canvas.getContext( '2d' );

  // for intro motion
  let mouseMoved = false;

  const pointer = {
    x: .5 * window.innerWidth,
    y: .5 * window.innerHeight,
  }
  const params = {
    pointsNumber: 40,
    widthFactor: .3,
    mouseThreshold: .6,
    spring: .4,
    friction: .5
  };

  const trail = new Array( params.pointsNumber );
  for ( let i = 0; i < params.pointsNumber; i++ ) {
    trail[i] = {
      x: pointer.x,
      y: pointer.y,
      dx: 0,
      dy: 0,
    }
  }

  // these are still running after being removed?!?

  // work with phones
  window.addEventListener( "click", e => {
    if ( window.cursor_disabled ) return;
    updateMousePosition( e.pageX, e.pageY );
  } );
  // how to debounce this to reduce CPU load?
  window.addEventListener( "mousemove", e => {
    if ( window.cursor_disabled ) return;
    mouseMoved = true;
    updateMousePosition( e.pageX, e.pageY );
  } );
  window.addEventListener( "touchmove", e => {
    if ( window.cursor_disabled ) return;
    mouseMoved = true;
    updateMousePosition( e.targetTouches[0].pageX, e.targetTouches[0].pageY );
  } );

  function updateMousePosition( eX, eY ) {
    pointer.x = eX;
    pointer.y = eY;
    window.lastMove = Date.now()
    if ( !window.running ) {
      // console.log('starting')
      window.running = true
      update(0)
      
    }
  }

  // only run if we've made a movement in the last 2 seconds?
  window.running = true
  window.lastMove = Date.now()
  
  // can we call it before we define it?
  setupCanvas();
  update( 0 );
  window.addEventListener( "resize", setupCanvas );

  ctx.strokeStyle = "hsl(" + Math.random() * 360 + ", 50%, 40%)"; 
  // $( '#inkcanvas' )[0].getContext( '2d' ).strokeStyle = "hsl(" + Math.random() * 360 + ", 50%, 40%)"; 
  

  function update( t ) {
    // for intro motion
    // if ( !mouseMoved ) {
    //   pointer.x = ( .5 + .3 * Math.cos( .002 * t ) * ( Math.sin( .005 * t ) ) ) * window.innerWidth;
    //   pointer.y = ( .5 + .2 * ( Math.cos( .005 * t ) ) + .1 * Math.cos( .01 * t ) ) * window.innerHeight;
    // }

    ctx.clearRect( 0, 0, canvas.width, canvas.height );
    trail.forEach( ( p, pIdx ) => {
      const prev = pIdx === 0 ? pointer : trail[pIdx - 1];
      const spring = pIdx === 0 ? .4 * params.spring : params.spring;
      p.dx += ( prev.x - p.x ) * spring;
      p.dy += ( prev.y - p.y ) * spring;
      p.dx *= params.friction;
      p.dy *= params.friction;
      p.x += p.dx;
      p.y += p.dy;
    } );

    ctx.lineCap = "round";
    ctx.beginPath();
    ctx.moveTo( trail[0].x, trail[0].y );

    for ( let i = 1; i < trail.length - 1; i++ ) {
      const xc = .5 * ( trail[i].x + trail[i + 1].x );
      const yc = .5 * ( trail[i].y + trail[i + 1].y );
      ctx.quadraticCurveTo( trail[i].x, trail[i].y, xc, yc );
      ctx.lineWidth = params.widthFactor * ( params.pointsNumber - i );
      ctx.stroke();
    }
    ctx.lineTo( trail[trail.length - 1].x, trail[trail.length - 1].y );
    ctx.stroke();
    
    if ( window.lastMove > Date.now() - 800 ) {
      // console.log('u')
      window.requestAnimationFrame( update );
    } else {
      // console.log('stopping')
      window.running = false
    }
  }

  function setupCanvas() {
    canvas.width = window.innerWidth;
    canvas.height = document.documentElement.scrollHeight;
  }
}