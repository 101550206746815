import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["contact", "with", "update"] // also works for plurals, this.nameTargets = array
  static values = { cid: Number, url: String } // assigning to this will select it & update the contact dom object.

  // could use a mutation observer to watch for disconnected class state: https://github.com/hotwired/turbo-rails/issues/498
  // would that even be called though?
  // and then move my visibilitychange stuff 
  // edge://discards/ to manually sleep/discard tabs - check what events are thrown.
  // try: window.online/offline events, pagehide, pageshow, 

  // #msg-window is persistent so this is not called each navigation
  initialize() {
    if ( window.cc ) return console.log( "Already initialized" ); // some bugs with this running more than once...
    window.cc = this
    this.connect_timer = setInterval( this.check_connected, 1200 )
    window.timefix()
    window.addEventListener( "resize", this.fix_msgbox_height )

    // if we've been gone more than 2 minutes, reload automatically upon return
    // better to use disconnect/reconnect event maybe?
    document.removeEventListener( 'visibilitychange', this.afk_check )
    document.addEventListener( 'visibilitychange', this.afk_check )
  }

  // this.reload doesn't work since callbacks aren't in the class anymore.  Does this.went_afk work?
  afk_check() {
    // console.log( 'afk_check', document.visibilityState, this.went_afk )
    if ( document.visibilityState === "visible" && this.went_afk && this.went_afk < new Date().getTime() - 60000 * 2 ) {
      this.went_afk = new Date().getTime() // reload cancels probably
      console.log( "reloading since gone since", ( new Date().getTime() - this.went_afk ) / 60000 ); cc.reload()
    }
    this.went_afk = new Date().getTime() // always set this so we don't loop.
  }

  connect() {
    this.clear_errors()
    if ( window.innerWidth >= 767 && !q( '.contact.active, .convo_list_mode' ) && q( 'a.contact' ) ) q( 'a.contact' ).click()
    $( '#msg-preview' ).hide()
    this.sortNames()
    this.fix_msgbox_height()
  }

  // called each time we have a new msg. increment unread & All-at-once= load new convo if missing, sortNames
  async check_convo( cid ) {
    console.log( "check_convo", cid )
    let list_mode = q( '.convo_list_mode' ), convo = q( `#with_${cid}` )

    if ( !convo && list_mode ) {
      if ( window.debug ) console.log( 'appending and loading for new convo' )
      // append the div for our empty ID
      q( '.convo_list_mode #container' ).insertAdjacentHTML( 'afterbegin', `<div id="with_${cid}">Loading...</div>` )
      await bring( 'get', `/convo/show/${cid}?list=1` ) // turbo will replace the contents
      // ideally would use JS to set the last message class 'unr'
      setTimeout( () => { window.read_more() }, 20 )

    } else if ( list_mode ) {
      // move updated convos to the top, see how it goes.  Sometimes returns Failed to execute 'prepend' on 'Element': The new child element contains the parent.
      console.log("list mode so trying to move this to the top of #container", convo.parentNode.parentNode)
      q( '#container' ).prepend( convo.parentNode.parentNode )

      // if they're > X size, remove the oldest messages
      let inside = q( '.msg_inside', convo )
      if ( queryAll( '.msg_left,.msg_right', inside ).length > 10 ) inside.removeChild( q( '.msg_left,.msg_right', inside ) )

    } else {
      this.sortNames() // resort left to keep messages header at top
      $( '.update_num' ).html( queryAll( '.convo_container .unread' ).length ) // #recount #unread

      // msg_convo_cid is only set in "conversation" mode - convo_list_mode only has with_id & with_outer_id
      // um, I don't think we set msg_convo_cid anymore? need to use with_id or with_outer_id - what sets .unread?
      // 1px solid #999
      // there's a kinda-major problem with all-at-once mode that it's hard to find new messages by a user when there a bunch on the page.
      // maybe we should rearrange them on the page after all?


      // THIS DOES NOT DO ANYTHING
      // or re-sort them when closing + opening the messages page.
      // let c = q( '#msg_convo_' + cid )

      // // if this one isn't already unread, mark it unread & increment the counter
      // if ( !c.classList.contains( 'unread' ) ) {
      //   $( '#msg_convo_' + cid ).addClass( 'unread' ) // mark this one unread
      //   $( '.update_num' ).html( parseInt( $( '.update_num:first' ).text() ) + 1 )
      // }      
    }
  }

  check_connected() {
    if ( !q( '#disconnected' ) ) return
    try {
      let state = q( 'body > turbo-cable-stream-source' ).subscription.consumer.connection.getState()
      q( '#disconnected' ).style.display = state == 'open' ? 'none' : 'block'
    } catch ( e ) {
      console.log( "Caught error checking connection", e )
    }
  }

  // hmm, actually not sure we want to change url state on click, since this will be in a sub-window most likely.
  // handle_popstate() {
  //   this.listen_popstate = document.addEventListener( 'popstate', ( e ) => {
  //     console.log( "popstate", e.state.url )
  //     if ( e.state.url ) {
  //       // click that person?
  //     }
  //   } )
  // }

  cidValueChanged() {
    if ( !this.cidValue ) return;
    for ( const ele of document.querySelectorAll( '.convo_container .active' ) ) ele.classList.remove( 'active' )
    let c = byid( 'msg_convo_' + this.cidValue )

    // post unread
    if ( c.classList.contains( 'unread' ) ) {
      bring( 'post', `/convo/read/${this.cidValue}` ) // post it's read, should run streamr esponses?
      c.classList.remove( 'unread' )
      let n = parseInt( $( '.update_num:first' ).text() ) - 1
      $( '.update_num' ).html( n < 0 ? 0 : n )
    }
    c.classList.add( 'active' );
    this.msg_tab_active()
    this.load_next_early()
    this.fix_msgbox_height()
  }

  // if the element under us isn't loaded yet, load it now...
  load_next_early() {
    let num = parseInt( byid( `msg_convo_${this.cidValue}` ).style.order )
    let nxt = query( `.contact[style*="order: ${num + 1}"]` )
    if ( nxt ) {
      let nxt_num = nxt.id.split( /_/ )[2]
      if ( !document.getElementById( `with_${nxt_num}` ) ) this.load_msg_with( nxt_num )
    }
  }

  // clicked a contact in the list. Activating triggers load_next_early
  // if we're on mobile and in right_active, then just close the left tab. disconnect msg doesn't trigger this yet tho
  choose( event ) {
    if ( window.debug ) console.log( "in choose", event )
    if ( document.body.classList.contains( 'right_active' ) ) return this.activate_left()
    this.cidValue = parseInt( event.currentTarget.id.split( /_/ )[2] )
    this.msg_tab_active() // needed for reopening same contact @ mobile
    
    if ( window.neonify ) setTimeout( () => { window.neonify( $( '.messages_list ' ) ) }, 100)  // update april fools @ dark mode
  }

  // toggle between list & conversation mode
  list_mode_on() {
    $.cookie( "l_m", 1, 999 )
    cc.cidValue = 0 // needs cleared to work on reload
    this.reload()
  }

  list_mode_off() {
    $.cookie( "l_m", null ) // remove cookie
    this.reload()
  }

  // fixing to reload without refreshing the full page. later, it's 5pm now and I want to visit M+P
  // TODO: once we remove old message system, move to <turbo-frame id="messages" src="/messages" loading="lazy"> & reload that way = uses mutexes
  reload( e ) {
    // console.log( "convo_controller reloading...", e )//, arguments.callee.caller.name )
    if ( e && e.target ) e.target.insertAdjacentHTML( 'afterend', "Reloading..." )

    if ( window.location.href.match( /\/convo/ ) ) return Turbo.visit( `/convo/${cc.cidValue > 0 ? cc.cidValue
      : ''}` ); // full-page vs. sidebar

    // keep the same person selected by passing it in
    $.get( `/convo/${cc.cidValue > 0 ? cc.cidValue : ''}?side=1`, function ( d ) {
      $( '#just_more' ).html( d )
      after_ajax( "#msg-window" )
      $( '#msg-window' ).addClass( 'opened' )
    } )
  }

  // deletes messages with a user/group - snoozes groups is also used in the top left menu dropdown.,
  hide( e ) {
    let w = e.target.closest( '.with' )
    let cid = w ? w.id.replace( /^with_/, '' ) : this.cidValue
    console.log( cid )
    let wit = q( `#with_${cid} h1` )
    if ( !wit ) return
    let group = w && w.querySelector( '.group_msgs' )

    if ( confirm( `This will hide all${group ? '' : ' past'} messages from this ${group ? 'group for 6 hours' : 'user'}, are you sure?` ) ) {
      bring( 'delete', `/convo/${cid}` )
      q( `#with_${cid}` ).closest( '.msg_list' ).remove() // why do we remove this first if we also remove the parent?
      q( `#msg_convo_${cid}` )?.remove()
      // q( `#with_outer_${cid}` )?.remove()

      // close menu
      q( `.btn--link.active` )?.classList.remove( 'active' )
      q( `.convomenu` ).style.display = 'none'
      if ( document.body.classList.contains( 'right_active' ) ) this.activate_left()
    }
  }

  report() {
    let usr = q( '#with_container .active .fa-external-link' )?.parentNode.href
    usr ||= '(please add username here)'
    window.location = `/ticket/add?ticket[body]=Complaint about messages with ${usr} - PLEASE INCLUDE SPECIFICS#contact`
  }

  // using css display:flex, can set order:# to order items. Updates the left column based on activity
  sortNames() {
    if ( q( '.convo_list_mode' ) || !q( '#col_left' ) ) return; //console.log( "skipping" );

    // make sure infinite bottom is at bottom - Needed even though order = 9999, not sure why
    if ( q( '#col_left .keep_at_bottom' ) ) q( '#col_left' ).appendChild( q( '#col_left .keep_at_bottom' ) )

    Array.from( document.querySelectorAll( '.contact' ) ).sort( ( a, b ) => {
      // console.log( a.dataset.date, b.dataset.date, a.dataset.date.localeCompare( b.date ) )
      return -a.dataset.date.localeCompare( b.dataset.date )
    } ).forEach( ( ele, idx ) => {
      ele.style.order = idx + 100
    } );
  }

  // save to localstorage every 5 length +/-, update height of right screen based on height of textarea
  save( e ) {
    this.saved ||= 0
    let len = e.target.value.length
    // console.log( 'convo save', len, this.saved )
    if ( len == 0 || len > this.saved + 5 || len < this.saved - 5 ) {
      localStorage[`convo_${this.cidValue}`] = e.target.value
      this.saved = len
    }
    cc.fix_msgbox_height() // hopefully not too slow? every 5 was being odd
  }

  // resize the fixed-height messages section depending upon how big the 'stuck at bottom' messages stays
  // wish there was a more elegant way to do this.
  // gotta do on word change for mobile devices since it normally doesn't until enter
  fix_msgbox_height( force ) {
    if ( q( 'convo_list_mode' ) ) return; // skip if all-at-once
    try {
      let textarea = q( '.active .convo_form' )//# textarea' )
      let tx = q( '.active .convo_form textarea' )?.style.height
      // cache unless height of textarea changes
      if ( !force && this.last_height == tx ) return
      this.last_height = tx
      // if ( !textarea || this.last_height == textarea.style.height ) return
      // if ( !textarea.style.height ) textarea.style.height = textarea.clientHeight + 'px'

      //calc how much height we need for 
      let w = textarea.closest( '.with' )
      let delt = w.children[0].clientHeight + textarea.offsetHeight + 40
      if ( q( 'body.convo' ) ) { // full-page
        delt += 155
        if ( window.innerWidth < 767 ) delt -= 94
      }
      w.children[1].style.height = `calc(100dvh - ${delt}px)`
      document.body.style.overflow = ''
    } catch ( e ) {
      console.log( "Caught error with fix_msgbox_height", e ); // why is textarea null sometimes?
    }
    return true
  }

  // finds the first one, so causing trouble if textareas to reply to comments
  restoreSave() {
    let v = localStorage[`convo_${this.cidValue}`]
    if ( v && q( '.active .convo_form textarea' ) ) q( '.active .convo_form textarea' ).value = v
  }

  // say something.  'all at once' needs alt method to get active form
  // this is adding temp messages to the first convo, not the current one still...
  // this could be entirely replaced with turbo now? - the 'instant' quality of tmp_msg is creat though.
  submit( e ) {
    if ( !e || !e.target ) console.log( "submit without event", e )
    let form = e.target
    let textarea = form.querySelector( 'textarea' )
    // console.log( 'form, textarea', form, textarea )
    // de-rich if we have an open ckeditor.  works... sometimes? Should prob try upgrading ckeditor entirely, ugh.
    // if ( q( '.active .convo_form .cke' ) ) {
    //   console.log( 'closing rich form', document.activeElement );
    //   // textarea.blur()
    //   document.activeElement.blur()
    //   // ugh how to get access to the ckeditor object properly? really struggling
    //   // q( '.active .convo_form .toggle-editor' ).click()
    //   for ( var name in CKEDITOR.instances ) {
    //     CKEDITOR.instances[name].destroy( true );
    //   }
    // }
    let oldVal = textarea.value
    if ( oldVal.length < 2 ) return false;

    // console.log(document.createTextNode(form.querySelector( 'textarea' ).value).textContent)
    // add a temp message for 'instant' feedback
    let txt = form.querySelector( 'textarea' ).value.replace(/&/g, '&amp;').replace(/>/g, '&gt;').replace(/</g, '&lt;')
    q( '.msg_inside', form.closest( '.with' ) ).insertAdjacentHTML( 'beforeEnd', `<div id='temp_msg' class="temp_msg msg_right"><div class="my05"></div><div class="bubble"><div>${txt}</div></div><img class="msg_avatar" height="72" src="${user.avatar}" width="72"></div>` ) // add temporary 'instant' message, replaced by turbo_stream
    // console.log( "added res to ", q( '.msg_inside', form.closest( '.with' ) ) )

    let body = new FormData( form )
    textarea.value = textarea.style.height = ''
    this.fix_msgbox_height()
    if ( q( '.ctip' ) ) q( '.ctip' ).classList.add( 'hidden' )

    // action can be group#say or convo#say
    bring( 'post', form.action, {
      body: body,
      ok: ( r ) => { // the stream renders automatically
        localStorage.removeItem( `convo_${this.cidValue}` )
        if ( document.activeElement ) document.activeElement.blur()
        $( ".dz-preview, .my_preview, .my_preview_form" ).remove()
        this.sortNames()
        // this.fix_msgbox_height()// again.  Still bugs when slow images load.
        setTimeout( function () { cc.fix_msgbox_height( 'force' ) }, 300 )
        textarea.focus()
        this.msg_bottom() // does this need a delay?
      },
      fail: ( response ) => {
        response.text.then( ( msg ) => {
          console.log( 'convo_controller#js fail', msg, response )
          q( '.msg_inside', form.closest( '.with' ) ).insertAdjacentHTML( 'beforeEnd', `<p><b>Error:</b> ${msg.substring( 0, 1200 )}</p>` )
          textarea.value = oldVal // put back the old message
          this.fix_msgbox_height()
        } )
      }
    } )
  }

  clear_errors() {
    let v = q( '.container > .notice, .container > .error' )
    if ( v ) setTimeout( () => { v.remove() }, 5000 )
  }

  // needs a short delay due to cidValueChanged callback
  scroll_contact() {
    setTimeout( () => {
      q( '.contact.active' ).scrollIntoView( { behavior: "smooth" } )
    }, 1 )
  }

  // idea = use arrow keys to move between elements, but doesn't work with the idea to automatically focus the textarea 
  arrows( e ) {
    if ( e.keyCode != 38 && e.keyCode != 40 && e.keyCode != 39 ) return
    // skip inside textareas
    var el = document.activeElement
    if ( el && ( el.tagName.toLowerCase() == 'input' && el.type == 'text' || el.tagName.toLowerCase() == 'textarea' ) ) return;

    if ( q( `.contact:not([style*="order"])` ) ) this.sortNames() // sort names if we haven't yet
    let num = parseInt( byid( `msg_convo_${this.cidValue}` ).style.order )

    if ( e.keyCode == 40 ) {// down
      let v = query( `.contact[style*="order: ${num + 1}"]` )
      if ( !v ) return console.log( `no down found for ${num + 1}` )
      v.click()
      this.scroll_contact()
      e.preventDefault()

    } else if ( e.keyCode == 38 ) { // up one - find by order
      let v = query( `.contact[style*="order: ${num - 1}"]` )
      if ( !v ) return console.log( `no up found for ${num - 1}` )
      v.click()
      this.scroll_contact()
      e.preventDefault()
    }
    else if ( e.keyCode == 39 || e.key == "Enter" ) {// right = focus on the input?
      query( `#with_${this.cidValue} .convo_form textarea` )?.focus()
    }
  }

  msg_tab_active() {
    if ( !document.getElementById( `with_${this.cidValue}` ) ) this.load_msg_with( this.cidValue ) // adds a temp tab immediately
    byid( `with_${this.cidValue}` ).parentNode.classList.add( 'active' )
    this.msg_bottom()
    this.restoreSave()
    if ( window.innerWidth < 767 ) document.body.classList.add( "right_active" )

    setTimeout( () => { q( '.active .convo_form textarea' )?.focus() }, 100 ) // focus the text field
  }

  // this might not be needed?
  msg_bottom() { let v = q( '.active .col_right' ); if ( v ) v.scrollTop = v.scrollHeight }

  load_msg_with( cid ) {
    if ( window.debug ) console.log( 'fetching convo  ' + cid )
    document.getElementById( 'with_container' ).insertAdjacentHTML( 'beforeend', `<div><div class='with' id="with_${cid}">Loading...</div></div>` )
    bring( 'get', `/convo/${cid}`, {
      ok: () => {
        this.msg_bottom();
        this.restoreSave();
        this.fix_msgbox_height()
        q( '.active .convo_form textarea' ).focus() // focus the text field
      }
    } )
  }

  name_tab_active() {
    document.body.classList.remove( "right_active" )
  }

  activate_left( e ) {
    if ( e && e.target.closest( '.contact' ) ) return;
    this.name_tab_active()
  }
}